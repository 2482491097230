
import { dynamicImportWithRetry } from "@fatso83/retry-dynamic-import"

// Since built script contains strange symbol polyfill
// We must load our polyfill before they script
import "core-js/modules/es.symbol"
import "core-js/modules/es.array.includes"

import "plugins/modernizr/modernizr-custom"

// Required since dynamic import is expecting `Promise` in `window`
import "core-js/modules/es.promise"

import {
  SimplePerformanceMetric,
} from "plugins/@figliolia/metrics"

import * as SentryGlobal from "@sentry/browser"

import {
  performance,
} from "plugins/user-timing-polyfill"
import PerformanceTracking from "apps/spacious_website/performance/tracking"

import {
  captureException,
} from "app/error_reporting"

import { markI18nAsReady } from "plugins/i18n-js/state"

// Change the path for different page type
import Application from "application/index"
window.App_Authentication_Manager = Application.App_Authentication_Manager

import { loadPolyfillsSometimes } from "apps/spacious_website/polyfills"

// region prevent HMR causing full reload

// Workaround from https://github.com/vitejs/vite/issues/6695#issuecomment-1069522995
if (import.meta.hot) {
  import.meta.hot.on('vite:beforeFullReload', () => {
    throw '(skipping full reload)'
  })
}

// endregion prevent HMR causing full reload


function initalizeSentry() {
  const Sentry = SentryGlobal

  // This can still be `null` sometimes
  // Just ignore those strange cases
  const data_element = document.getElementById("app-data-container")
  if (data_element == null) { return }

  const sentry_dsn = data_element.getAttribute("data-sentry-dsn")
  const app_version = data_element.getAttribute("data-app-version-git-revision-sha")
  // Non-prod env probably won't have this set
  if (sentry_dsn == null) { return }

  const rails_env_from_data = data_element.getAttribute("data-rails-env") || "unknown"

  Sentry.init({
    dsn:          sentry_dsn,
    release:      (typeof app_version === "string" && app_version.length > 0) ? app_version : undefined,
    environment:  rails_env_from_data,

    // Attaches stack traces to pure capture message / log integrations
    attachStacktrace: true,

    integrations: [
      Sentry.globalHandlersIntegration({
        onerror:              true,
        onunhandledrejection: false,
      }),
    ],

    denyUrls: [
      // All from user agent
      // Mozilla/5.0 (Unknown; Linux x86_64) AppleWebKit/538.1 (KHTML, like Gecko) Safari/538.1 Daum/4.1
      /sp\.pl/,
      // Google Maps API
      /maps-api-v3/,
      // InspectLet
      /inspectlet\.js/,
      // Google Ads
      /adsbygoogle\.js/,
      // Google DoubleClick
      /doubleclick.net/,
      // Facebook
      /fbevents\.js/,
      /xfbml\.customerchat\.js/,
      // FB too, example URL:
      // https://connect.facebook.net/signals/config/492404124272136?v=2.7.7
      /signals\/config/,
      // Some Wifi Provider injected script
      /js\.union-wifi\.com\/zm\.js/,
      // Anti-virus plugin script
      /kaspersky-labs\.com/,
      // Possible malware
      // https://malwaretips.com/blogs/cdncache1-a-akamaihd-net-removal/
      /akamaihd\.net\/loaders/,
      // Shopperifymac, adware?
      /secure\.shopperifymac\.com\/servicejs\/components\/js/,
      // Twitter got bugs sometimes
      "https://platform.twitter.com/widgets.js",
      // Google AMP
      /cdn\.ampproject\.org/,
      // Optimizely
      /cdn\.optimizely\.com/,
      // Google YOLO
      /smartlock\.google\.com/,
      /accounts\.google\.com/,
      // Truepush
      /sdk\.truepush\.com/,
      /sdki\.truepush\.com/,
      // Some strange extension site?
      /image\.uc\.cn/,
      // Yahoo Gemini (Yahoo Advertising)
      /s\.yimg\.com/,
      // Some unknown China script
      /feiqinwangshi\.cn/,
      // Google One Tap
      /m=gis_client_library/,
      /m=gis_id_library/,
      // Baidu Maps
      /api\.map\.baidu\.com/,
    ],

    ignoreErrors: [
      // Don't send reports for message with `elt.parentNode`
      // https://github.com/getsentry/raven-js/issues/756
      //
      // DO NOT use code like
      // `if data.exception?.message.indexOf("evaluating 'elt.parentNode'") isnt -1`
      /evaluating 'elt\.parentNode'/,

      // The "out of memory" error we are getting are mostly caused by Window XP
      // And are ALL caused by Firefox 50.0 (no other versions)
      //
      // See https://sentry.io/spacious/spacious-rails-js/issues/193699433
      //
      // Thus we don't care about this kind of error
      //
      // `if data.exception?.message.indexOf("evaluating 'elt.parentNode'") isnt -1`
      /out of memory/,

      // Error report from old browsers which is useless for debugging
      // https://github.com/getsentry/raven-js/issues/360
      "[object Event]",

      // This could be caused by the raven-js or other stuff
      // But useless to us anyway (and waiting quota)
      "undefined",

      // Error generated by a bug in auto-fill library from browser
      // https://github.com/getsentry/sentry/issues/5267
      /Blocked a frame with origin/,

      // Error from browser or extension something
      // https://forum.sentry.io/t/empty-stacktrace-and-exceptions-from-unknown-code-base/1143
      /Player is not defined/,
      /MacPlayer is not defined/,
      /\$skyuc_src is not defined/,

      // Doesn't seem to be our script issue
      /UET is not defined/,
      /'UET' is undefined/,

      // Some Android browser internal issue
      /window\.naverAppDic\.checkDomStatus is not a function/,
      /inappbrowser\.notifyOGTag is not a function/,

      // Some undefined variable, but our scripts do not contain them
      "Can't find variable: inf",
      "Can't find variable: nan",
      "Can't find variable: MyAppGetLinkHREFAtPoint",
      "Can't find variable: MyAppGetLinkSRCAtPoint",
      "Can't find variable: MyAppGetHTMLElementsAtPoint",
      "Can't find variable: MyAppGetLinkTitleNameAtPoint",
      "Can't find variable: GetHTMLElementsAtPoint",

      // IE bug, can only be fixed by updating IE
      // https://support.microsoft.com/en-us/help/3191336/-permission-denied-script-error-in-internet-explorer-11
      "Permission denied",
      "沒有使用權限",

      // "Failed" AJAX requests, rejected promises etc.
      "Non-Error exception captured with keys",

      // Strange Newtonsoft.Json which seems to be some browser plugin
      /Newtonsoft\.Json/,

      // Chrome Mobile only, assumed some native code
      /removeNightMode/,
      /document\.getElementsByClassName\.ToString is not a function/,

      // Some DataCloneError on some var not defined by us
      /Failed to execute 'postMessage' on 'Window'/,

      // Optimizely
      /_isMatchingDomain is not defined/,

      // Bug in Chrome iOS related to autofilling forms, it should not impact our app. Marking as resolved
      /__gCrWeb/,

      // Specific phone builtin JS issue
      /diableNightMode is not defined/,

      // browser built-in/extension JS
      /loginFormData.userNameValue/,

      // Android app
      // https://gist.github.com/pioug/b006c983538538066ea871d299d8e8bc
      /vid_mate_check is not defined/,

      // indexedDB used by other parties only
      // https://developer.mozilla.org/en-US/docs/Web/API/IndexedDB_API
      /Failed to execute 'transaction' on 'IDBDatabase'/,

      // UC Browser only without stacktrace (native code)
      /JSON Parse error: Unexpected identifier/,

      // Mobile Safari UIWebView only, probably specific apps
      /Can't find variable: loadHomepageTiles/,
      /Can't find variable: pktAnnotationHighlighter/,
      /Can't find variable: getFirstImage/,
      /Can't find variable: getAbstract/,
      /Can't find variable: _bdbox_js/,
      /Can't find variable: GetImageTagSrcFromPoint/,

      // QQ Browser Mobile
      /x5onSkinSwitch is not defined/,
      /Identifier 'qbSniffJsVersion' has already been declared/,

      // FB SDK
      /Unexpected token u in JSON at position 0/,

      // User/Browser specific issue, not in our code
      // Possibly browser extension
      /Can't find variable: commonCallback/,
      /androidInterface is not defined/,
      /getRandomIntInclusive is not defined/,
      /Can't find variable: UC_Pub_Base_WebView_isFinish/,

      // Vivo devices?
      /vivoNewsDetailPage is not defined/,
      /vivoNewsDetailPage.getNewsReadStatus4Vivo is not a function/,

      // XiaoMi devices
      /Cannot read property 'title' of null/,

      // QQ Browser Mobile
      /WeixinJSBridge is not defined/,

      // Xiaomi Devices
      /window\.readmode\.onReadModeDataReady is not a function/,

      // Unknown script like `js_snapshot_var_0xdeadbeefz09_5c.throwErr`
      /DOMException is not defined/,

      // Huawei Browser
      /hwbrNotifyNotInMultiWinMode is not defined/,
      /hwbrNotifyInMultiWinMode is not defined/,
      /hwbrNotifyScreenConfigChanged is not defined/,

      // 3rd party injected script from some Chinese news site
      // http://www.wewyy.com/archives/335
      /ToutiaoJSBridge is not defined/,

      // This is caused by calling document.createEvent
      // But it happens only inside FB crawler
      // https://github.com/aFarkas/lazysizes/issues/520
      /Illegal invocation/,

      // Non standard/documented JS called from mostly UC Browser
      /this.hostIndex.push is not a function/,
    ],

    beforeSend: function (event) {
      const is_valid_user_agent = [
        // Ignore any visitor with user agent including
        // Chrome 45
        // Since this is faked by some search crawler
        /^(.+Chrome\/45\.0\.2454\.93.+)$/,
        // User agent with too many "Unexpected identifier" errors
        /Cocolyzebot/,
      ].every(function(regex) { return !regex.test(window.navigator.userAgent) })

      return is_valid_user_agent ? event : null
    },
  })
}


// Not waiting for `window._spacious_main_css_loaded` since
// it's slow on slow networks (mobile devices mostly)
// WaitObjectByPathPromise("window._spacious_main_css_loaded")
Promise.resolve()
.then(async () => {
  PerformanceTracking.init()

  new SimplePerformanceMetric("initalizeSentry").measure(initalizeSentry)

  await (new SimplePerformanceMetric("loadPolyfillsSometimes").measureAsync(loadPolyfillsSometimes))

  await new SimplePerformanceMetric("i18n-js:import").measureAsync(() => {
    return dynamicImportWithRetry(() => import("plugins/i18n-js"))
  })
  .then(({default: I18n}) => {
    new SimplePerformanceMetric("i18n-js:run").measure(() => {
      // region assign existing locale
      try {
        if (typeof window.I18n.defaultLocale === "string") {
          I18n.defaultLocale = window.I18n.defaultLocale
        }
      } catch (e) {
        // Do nothing
      }
      try {
        if (typeof window.I18n.locale === "string") {
          I18n.locale = window.I18n.locale
        }
      } catch (e) {
        // Do nothing
      }

      window.I18n = I18n
      markI18nAsReady(I18n)
      // endregion assign existing locale
    })
  })

  await new SimplePerformanceMetric("es6_page_loader:import").measureAsync(() => {
    return dynamicImportWithRetry(() => import("application/es6_page_loader.es6"))
  })
  .then(({default: es6_start}) => {
    new SimplePerformanceMetric("es6_page_loader:run").measure(es6_start)
  })

  await new SimplePerformanceMetric("app/vue/semi-auto-init:import").measureAsync(() => {
    return dynamicImportWithRetry(() => import("app/vue/semi-auto-init"))
  })
  .then((VueSemiAutoInit) => {
    new SimplePerformanceMetric("app/vue/semi-auto-init:run").measure(() => {
      VueSemiAutoInit.init()
    })
  })
})
